<script>
import AdminLayout from "@layouts/admin";
import DataTable from "@components/data-table";
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
    name: "ManageDealerNoc",
    components: {
        AdminLayout,
        DataTable,
    },
    data() {
        return {
            items: [],
            count: 0,
            headers: [
                { value: "name", text: "Name", width: "170", align: "left" },
                { value: "phone", text: "Phone", width: "100", align: "left" },
                { value: "email", text: "Email", width: "200", align: "left" },
                {
                    value: "vehicleType",
                    text: "Vehicle Type",
                    width: "110",
                    align: "left",
                },
                {
                    value: "isPaymantDone",
                    text: "Is Payment Done",
                    width: "135",
                    align: "center",
                },
                {
                    value: "paymentAmount",
                    text: "Amount",
                    width: "100",
                    align: "center",
                },
                {
                    value: "state",
                    text: "State",
                    width: "180",
                    align: "center",
                },
            ],
            limit: 0,
            current: 1,
            isModalVisible: false,
        };
    },
    methods: {
        async getdata(search = null) {
            try {
                const query = {
                    limit: this.limit,
                    offset: (this.current - 1) * this.limit,
                };

                if (search) {
                    query.search = search;
                }

                const { count, rows } = await this.$http.get(
                    "dealer/find-and-count-noc",
                    query
                );

                this.items = rows;
                this.items = this.items.map((a) => ({
                    ...a,
                    isPaymantDone: a.isPaymentDone ? "Yes" : "No",
                    vehicleType: this.humanizeText(a.vehicleType),
                    paymentAmount: `₹ ${a?.Transaction?.amount || process.env.VUE_APP_NOC_AMOUNT}`,
                }));
                this.count = count;
            } catch (err) {
                console.log(err);
                this.$utility.showErrorMessage(err.message);
            }
        },
        humanizeText(text) {
            if (text) {
                text = text.split("_").join("-");
                return text.charAt(0).toUpperCase() + text.slice(1);
            }
            return "Na";
        },
        async handleClick(e) {
            try {
                const response = await this.$http.updateById(
                    "user/approve-delivery-boy",
                    e.deliveryId
                );
                this.$utility.showSuccessMessage(response.message);
                this.getdata();
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        handleFetch(obj) {
            this.current = obj.current;
            this.limit = obj.limit;
            this.getdata();
        },
        debounceSearch: debounce(function (text) {
            this.getdata(text);
        }, 600),
        handleModal() {
            this.isModalVisible = true;
        },
        handleRowClick(row, column, e) {
            this.$utility.goToPage("noc.detail", { nocId: row.id });
        },
    },
    computed: {
        ...mapGetters("account", ["loggedInUser"]),
        isRegsterationAllowed() {
            const balance =  this.loggedInUser?.Dealer?.availableBalance ? parseInt(this.loggedInUser.Dealer.availableBalance) : 0
            console.log(balance)
            if(balance < parseInt(process.env.VUE_APP_NOC_AMOUNT)) {
                return true
            }
            return false
        }
    }
};
</script>
<template lang="pug">
admin-layout
    data-table(
        :showSearch="true",
        :count="count",
        @fetch="handleFetch",
        @search="debounceSearch",
        :showAdd="true",
        addTitle="Register NOC",
        @addClick="$utility.goToPage('register.dealer.noc')"
        :disableAdd="isRegsterationAllowed"
    )
        el-table(
            :data="items",
            @row-click="handleRowClick",
            style="width: 100%",
            :fit="true",
            size="medium"
        )
            el-table-column(
                v-for="col in headers",
                :key="col.id",
                :label="col.text",
                :width="col.width",
                :align="col.align"
            )
                template(#default="{row}")
                    span.cursor-pointer(
                        v-if="row[col.value]",
                        v-html="row[col.value]"
                    )
                    span(v-else) NA
</template>
